import { makeStyles } from '@material-ui/core/styles';
import { GRAY_PRIMARY, WHITE, GRAY_LIGHT, THERMOS_RED, TEXT_TOP_MENU, GRAY_SECONDARY } from '@theme_color';
import {
    CreateMargin, CreatePadding, CenterAbsolute, Centering,
} from '@theme_mixins';

const useStyles = makeStyles((theme) => ({
    cmsContainer: {
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        overflowX: 'hidden',
        ...CreatePadding(0, 0, 0, 0),

        // style homepage
        '& .mgz-element:not(.full_height)>div.mgz-element-inner>.row>.mgz-column>*': {
            '@media screen and (min-width: 768px)': {
                padding: 0,
            }
        },
        '& .section': {
            '& .MuiTypography-root': {
                margin: 0,
            },
            '& .row': {
                marginBottom: 0,
                lineHeight: 0,
            },
            '&.section-full': {
                display: 'block',
                width: 'auto',
                '@media (min-width: 1024px)': {
                    width: '100vw',
                    position: 'relative',
                    marginLeft: '-50vw',
                    left: '50%',
                },
                '@media (min-width: 768px) and (max-width: 1023px)': {
                    margin: '0 -20px',
                },
                '@media (max-width: 767px)': {
                    margin: '0 -16px',
                }
            },
            '&.section-full-contain': {
                display: 'block',
                width: 'auto',
                '@media (min-width: 1024px)': {
                    width: '100vw',
                    position: 'relative',
                    marginLeft: '-50vw',
                    left: '50%',
                },
                '@media (min-width: 768px) and (max-width: 1023px)': {
                    margin: '0 -20px',
                },
                '@media (max-width: 767px)': {
                    margin: '0 -16px',
                },
                '& > .mgz-element-inner': {
                    width: '100%',
                    margin: '0 auto',
                    '@media (min-width: 1024px)': {
                        maxWidth: '1280px',
                        padding: '0 40px',
                    },
                    '@media (min-width: 768px) and (max-width: 1023px)': {
                        padding: '0 20px',
                    },
                    '@media (max-width: 767px)': {
                        padding: '0 16px',
                    }
                }
            },
            '&.section-main-slider': {
                width: 'auto',
                display: 'block',
                '& #home-banner': {
                    backgroundColor: GRAY_LIGHT,
                },
                '@media (min-width: 1024px)': {
                    margin: '0 -40px',
                },
                '@media (min-width: 768px) and (max-width: 1023px)': {
                    margin: '0 -20px',
                },
                '@media (max-width: 767px)': {
                    margin: '0 -16px',
                },
                '& .magezon-widget': {
                    marginBottom: 0,
                    '& .arrow': {
                        margin: 0,
                        width: 44,
                        height: 44,
                        borderRadius: '50%',
                        backgroundColor: WHITE,
                        color: TEXT_TOP_MENU,
                        boxShadow: '0px 4px 6px -1px rgba(17, 24, 39, 0.10), 0px 2px 4px -1px rgba(17, 24, 39, 0.06)',
                        transition: 'background-color .3s ease',
                        '& svg': {
                            height: 15,
                            width: 15,
                        },
                        '&:hover': {
                            border: 'none',
                            backgroundColor: GRAY_LIGHT,
                        }
                    },
                    '& .dots': {
                        '& > div': {
                            height: 10,
                            width: 10,
                            backgroundColor: '#D1D5DB',
                            '&.dot-active': {
                                backgroundColor: THERMOS_RED,
                                width: 28,
                            }
                        }
                    }
                }
            },
            '&.section-product-slider': {
                padding: '40px 0 65px',
                '@media (max-width: 767px)': {
                    padding: '25px 0 50px',
                },
                '& .left-title-column': {
                    '& h2': {
                        textTransform: 'unset !important',
                        '@media (max-width: 767px)': {
                            fontSize: '18px !important',
                        },
                    }
                },
                '& .right-action-column': {
                    display: 'flex',
                    alignItems: 'center',
                    '& p': {
                        margin: 0,
                    },
                    '& a': {
                        display: 'inline-block',
                        lineHeight: 'normal',
                        letterSpacing: '0',
                        '@media (max-width: 767px)': {
                            fontSize: '14px !important',
                            '& span': {
                                fontSize: '14px !important',
                            }
                        },
                    },
                },
                '& .mgz-product-slider': {
                    marginBottom: 0,
                    paddingBottom: 0,
                    '& .mgz-product-slider-content': {
                        margin: '0 -10px',
                        '@media (max-width: 767px)': {
                            margin: '0 -5px',
                            '& .slick-slider': {
                                margin: '0 -16px',
                            }
                        },
                        '& .slick-list': {
                            paddingTop: '25px !important',
                            paddingBottom: 0,
                            height: 'auto !important',
                        }
                    },
                    '& .slick-slide': {
                        padding: '0 10px',
                        '& .MuiGrid-item': {
                            width: '100%',
                        },
                        '@media (max-width: 767px)': {
                            padding: '0 5px',
                        },
                    },
                    '& .slick-slider': {
                        '& .slick-dots': {
                            position: 'absolute',
                            bottom: '-25px',
                            '& li': {
                                height: 'auto',
                                width: 'auto',
                                margin: '0 2px',
                                '&.slick-active': {
                                    '& a div': {
                                        backgroundColor: THERMOS_RED,
                                        width: 28,
                                    }
                                },
                                '& a': {
                                    display: 'block',
                                    '& div': {
                                        backgroundColor: '#D1D5DB',
                                    }
                                }
                            }
                        }
                    },
                    '& .mgz-product-slider-nav': {
                        width: 'calc(100% + 44px)',
                        left: '50%',
                        top: '50%',
                        bottom: 'auto',
                        transform: 'translate(-50%, -50%)',
                        '@media (max-width: 1023px)': {
                            width: 'calc(100% - 20px)',
                        },
                        '& .mgz-product-slider-nav--btn': {
                            margin: 0,
                            width: 44,
                            height: 44,
                            borderRadius: '50%',
                            backgroundColor: WHITE,
                            color: TEXT_TOP_MENU,
                            boxShadow: '0px 4px 6px -1px rgba(17, 24, 39, 0.10), 0px 2px 4px -1px rgba(17, 24, 39, 0.06)',
                            '& svg': {
                                height: 20,
                                width: 20,
                            },
                            '&:hover': {
                                border: 'none',
                                backgroundColor: GRAY_LIGHT,
                            }
                        }
                    },
                    '& .mgz-single-product-card': {
                        padding: 0,
                        '& .link-image': {
                            border: '1px solid #E5E7EB',
                            borderRadius: 6,
                            overflow: 'hidden',
                            zIndex: '1',
                            marginBottom: 20,
                            padding: '50px 17px',
                        },
                        '& .product-name-wrap': {
                            marginBottom: 8,
                            width: '100%',
                            '& a, h4': {
                                color: `${GRAY_SECONDARY} !important`,
                            },
                            '& h4': {
                                fontWeight: '400',
                                fontSize: 18,
                                '@media (max-width: 767px)': {
                                    fontSize: 14,
                                },
                            }
                        },
                        '& .price-wrap': {
                            justifyContent: 'flex-start',
                            '& *': {
                                textAlign: 'left',
                            },
                            '& .price_text': {
                                fontSize: 18,
                                fontWeight: '700',
                                letterSpacing: 'normal',
                                color: GRAY_PRIMARY,
                                marginLeft: '0 !important',
                                '@media (max-width: 767px)': {
                                    fontSize: 14,
                                },
                                '&.regular_price': {
                                    marginBottom: 8,
                                    '@media (max-width: 767px)': {
                                        marginLeft: 0,
                                    },
                                },
                                '&.oldprice': {
                                    fontSize: 14,
                                    fontWeight: '400',
                                    color: '#9CA3AF',
                                    '@media (max-width: 767px)': {
                                        fontSize: 12,
                                    },
                                },
                                '&.discount': {
                                    marginLeft: '5px !important',
                                    fontSize: 14,
                                    fontWeight: '600',
                                    color: '#DC2626',
                                    '@media (max-width: 767px)': {
                                        fontSize: 12,
                                    },
                                }
                            }
                        }
                    }
                }
            },
            '&.section-image-column': {
                padding: '38px 0',
                '@media (max-width: 767px)': {
                    padding: '24px 0',
                },
                '& .row': {
                    margin: '0 -10px',
                    width: 'auto',
                    '@media (max-width: 767px)': {
                        margin: '0 -6px',
                    },
                    '& > .mgz-column': {
                        padding: 10,
                        '@media (max-width: 767px)': {
                            padding: 6,
                        },
                        '&.image-big .magezon-image.magezon-img-outline picture': {
                            paddingBottom: '51.7%',
                        },
                        '& .magezon-image.magezon-img-outline': {
                            '& > a': {
                                display: 'block',
                                '& > span': {
                                    width: 'auto !important',
                                },
                                '& picture': {
                                    display: 'block',
                                    position: 'relative',
                                    overflow: 'hidden',
                                    borderRadius: 5,
                                    paddingBottom: '107%',
                                    zIndex: '1',
                                    '& > *': {
                                        position: 'absolute',
                                        left: 0,
                                        top: 0,
                                        height: '100% !important',
                                        width: '100%',
                                        objectFit: 'cover',
                                    }
                                }
                            },
                            '& .mgz-img-content': {
                                zIndex: '2',
                                '& .mgz-img-content-title': {
                                    fontSize: 20,
                                    color: WHITE,
                                    fontWeight: '600',
                                    lineHeight: 'normal',
                                    '@media (max-width: 767px)': {
                                        fontSize: 16,
                                    }
                                }
                            }
                        }
                    }
                }
            },
            '&.section-image-2-column': {
                width: 'auto',
                display: 'block',
                padding: '20px 0 40px',
                '@media (min-width: 1024px)': {
                    margin: '0 -40px',
                },
                '@media (min-width: 768px) and (max-width: 1023px)': {
                    margin: '0 -20px',
                },
                '@media (max-width: 767px)': {
                    margin: '0 -16px',
                    padding: '8px 0 24px',
                },
                '& .column-image': {
                    position: 'relative',
                    '& .magezon-image.magezon-img-outline': {
                        display: 'block',
                        '@media (max-width: 767px)': {
                            marginTop: '-1px',
                        },
                        '& > span': {
                            width: 'auto !important',
                        },
                        '& picture': {
                            display: 'block',
                            position: 'relative',
                            overflow: 'hidden',
                            paddingBottom: '84.4%',
                            '@media (max-width: 767px)': {
                                paddingBottom: '66.6%',
                            },
                            '& > *': {
                                position: 'absolute',
                                left: 0,
                                top: 0,
                                height: '100% !important',
                                width: '100%',
                                objectFit: 'cover',
                            }
                        }
                    },
                    '& .image-desc': {
                        position: 'absolute',
                        left: 0,
                        bottom: 0,
                        width: '100%',
                        backgroundColor: 'transparent !important',
                        padding: '60px 50px !important',
                        '@media (max-width: 767px)': {
                            padding: '24px !important',
                        },
                        '& .row': {
                            backgroundColor: 'transparent !important',
                            maxWidth: 500,
                        },
                        '& *': {
                            textTransform: 'unset !important',
                        },
                        '& .small-heading': {
                            marginBottom: 8,
                            '@media (max-width: 767px)': {
                                marginBottom: 0,
                            },
                            '& h3': {
                                lineHeight: '28px',
                                '@media (max-width: 767px)': {
                                    fontSize: '14px !important',
                                    lineHeight: '20px',
                                },
                            }
                        },
                        '& .big-heading': {
                            marginBottom: 24,
                            '@media (max-width: 767px)': {
                                marginBottom: 12,
                            },
                            '& h2': {
                                lineHeight: '40px',
                                '@media (max-width: 767px)': {
                                    fontSize: '18px !important',
                                    lineHeight: '28px',
                                },
                            }
                        },
                        '& .mgz-button': {
                            '& .MuiButton-containedPrimary': {
                                backgroundColor: `${WHITE} !important`,
                                color: '#000 !important',
                                fontWeight: '600',
                                fontFamily: 'Inter',
                                letterSpacing: 0,
                                minWidth: '166px !important',
                                padding: '10px 15px',
                                '@media (max-width: 767px)': {
                                    fontSize: '12px !important',
                                    padding: '6px 15px',
                                    minWidth: '133px !important',
                                },
                                '&:hover': {
                                    backgroundColor: `${THERMOS_RED} !important`,
                                    color: `${WHITE} !important`,
                                }
                            }
                        }
                    }
                }
            },
            '&.section-slider-content': {
                padding: '20px 0 12px',
                '& .mgz-product-slider': {
                    marginBottom: 0,
                    paddingBottom: 0,
                    '& .mgz-product-slider-content': {
                        '& .slick-list': {
                            paddingBottom: 0,
                            paddingTop: 0,
                            height: 'auto !important',
                        }
                    },
                    '& .slick-slide': {
                        padding: 0,
                        '& .mgz-section-item': {
                            margin: 0,
                            borderRadius: 8,
                            overflow: 'hidden',
                            zIndex: '1',
                            backgroundColor: '#F3F4F6',
                        },
                        '& > div, .mgz-section, .mgz-section-item': {
                            '@media (max-width: 767px)': {
                                height: '100%',
                            },
                        }
                    },
                    '& .slick-slider': {
                        '& .slick-dots': {
                            position: 'absolute',
                            bottom: '-25px',
                            '& li': {
                                height: 'auto',
                                width: 'auto',
                                margin: '0 2px',
                                '&.slick-active': {
                                    '& a div': {
                                        backgroundColor: THERMOS_RED,
                                        width: 28,
                                    }
                                },
                                '& a': {
                                    display: 'block',
                                    '& div': {
                                        backgroundColor: '#D1D5DB',
                                    }
                                }
                            }
                        }
                    },
                    '& .mgz-product-slider-nav': {
                        width: 'calc(100% + 44px)',
                        left: '50%',
                        top: '50%',
                        bottom: 'auto',
                        transform: 'translate(-50%, -50%)',
                        '@media (max-width: 1023px)': {
                            width: 'calc(100% - 20px)',
                            top: '37%',
                        },
                        '@media (max-width: 767px)': {
                            top: '36%',
                        },
                        '@media (max-width: 580px)': {
                            top: '21%',
                        },
                        '@media (max-width: 360px)': {
                            top: '19%',
                        },
                        '& .mgz-product-slider-nav--btn': {
                            margin: 0,
                            width: 44,
                            height: 44,
                            borderRadius: '50%',
                            backgroundColor: WHITE,
                            color: TEXT_TOP_MENU,
                            boxShadow: '0px 4px 6px -1px rgba(17, 24, 39, 0.10), 0px 2px 4px -1px rgba(17, 24, 39, 0.06)',
                            '& svg': {
                                height: 20,
                                width: 20,
                            },
                            '&:hover': {
                                border: 'none',
                                backgroundColor: GRAY_LIGHT,
                            }
                        }
                    },
                    '& .left-content-text': {
                        width: 'calc(100% - 385px)',
                        flexBasis: 'calc(100% - 385px)',
                        maxWidth: 'calc(100% - 385px)',
                        padding: '48px 50px',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        '@media (max-width: 1023px)': {
                            order: 2,
                            width: '100%',
                            flexBasis: '100%',
                            maxWidth: '100%',
                            padding: '32px 32px 15px',
                        },
                        '& h2': {
                            textTransform: 'none !important',
                            '@media (max-width: 1023px)': {
                                fontSize: '24px !important',
                            },
                        },
                        '& p': {
                            lineHeight: '28px',
                            '@media (max-width: 1023px)': {
                                fontSize: '16px !important',
                                lineHeight: '24px',
                            },
                            '& *': {
                                '@media (max-width: 1023px)': {
                                    fontSize: '16px !important',
                                    lineHeight: '24px',
                                },
                            }
                        },
                        '& a': {
                            color: `${THERMOS_RED} !important`,
                            lineHeight: 'normal',
                        }
                    },
                    '& .right-image': {
                        width: '385px',
                        flexBasis: '385px',
                        maxWidth: '385px',
                        '@media (max-width: 1023px)': {
                            order: 1,
                            width: '100%',
                            flexBasis: '100%',
                            maxWidth: '100%',
                        },
                        '& div, span': {
                            height: '100%',
                        },
                        '& .magezon-image.magezon-img-outline': {
                            display: 'block',
                            '& > span': {
                                width: 'auto !important',
                            },
                            '& picture': {
                                display: 'block',
                                position: 'relative',
                                overflow: 'hidden',
                                minHeight: '385px',
                                height: '100%',
                                '@media (max-width: 1023px)': {
                                    paddingBottom: '70%',
                                    minHeight: '0',
                                    height: 'auto',
                                },
                                '@media (max-width: 767px)': {
                                    paddingBottom: '100%',
                                    minHeight: '0',
                                    height: 'auto',
                                },
                                '& > *': {
                                    height: '100% !important',
                                    width: '100%',
                                    objectFit: 'cover',
                                    '@media (max-width: 1023px)': {
                                        position: 'absolute',
                                    },
                                }
                            }
                        },
                    }
                }
            }
        }
    },
    container: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        overflowX: 'hidden',
        ...CreatePadding(0, 0, 30, 0),
    },
    containerCategory: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        overflowX: 'hidden',
    },
    category: {
        height: '100%',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        ...CreateMargin(30, 0, 30, 0),
    },
    header: {
        [theme.breakpoints.between('sm', 'md')]: {
            width: '100vw',
        },
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: GRAY_PRIMARY,
    },
    logo: {
        position: 'absolute',
        zIndex: 99,
        ...CenterAbsolute,
    },
    titleLogo: {
        fontWeight: 'bold',
        fontFamily: 'Playfair Display',
        color: WHITE,
    },
    imgLogoContainer: {
        paddingTop: 'unset !important',
    },
    imgLogo: {
        marginTop: '25%',
        width: '100px !important',
        height: '100% !important',
        position: 'relative !important',
    },
    imgLogoHamburger: {
        width: 100,
        height: '100%',
    },
    skeletonWrapper: {
        padding: '0 0 12px 0',
        width: '100%',
        postion: 'relative',
        marginTop: '20px',
        '& .logo': {
            width: 100,
            top: 5,
            position: 'absolute',
            zIndex: 99,
            ...CenterAbsolute,
        },
        [theme.breakpoints.down('sm')]: {
            '& .MuiGrid-spacing-xs-2': {
                marginTop: 10,
            },
            marginTop: 0,
        },
    },
    skeleton: {
        marginBottom: '8px',
    },
    divMessage: {
        minHeight: 100,
        width: '100%',
        ...Centering,
    },
    labelCategory: {
        paddingTop: 20,
        paddingBottom: 20,
        display: 'flex',
        justifyContent: 'center',
        [theme.breakpoints.down('sm')]: {
            paddingTop: 10,
            paddingBottom: 10,
        },
    },
    contentContainer: {
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: 70,
        [theme.breakpoints.down('sm')]: {
            // marginLeft: '20px',
            // marginRight: 'auto',
            marginTop: 10,
            marginBottom: 10,
        },
        [theme.breakpoints.up('sm')]: {
            width: 750,
        },
        [theme.breakpoints.up('md')]: {
            width: 970,
        },
        [theme.breakpoints.up('lg')]: {
            width: 1170,
        },
    },
    features: {
        marginTop: 20,
        marginBottom: 30,
        width: '100%',
        height: '100%',
        [theme.breakpoints.down('sm')]: {
            padding: '0',
            marginTop: 10,
            marginBottom: 10,
        },
    },
    featuresBox: {
        boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
        border: `1px solid ${GRAY_LIGHT}`,
        padding: 0,
        [theme.breakpoints.down('sm')]: {
            margin: '0',
            boxShadow: 'unset',
            border: '0',
        },
    },
    contentFeatured: {
        transition: '0.3s',
        borderTopLeftRadius: 5,
        borderBottomLeftRadius: 5,
        [theme.breakpoints.up('sm')]: {
            padding: 8,
        },
    },
    contentMobileFeatured: {
        [theme.breakpoints.down('sm')]: {
            padding: '0',
        },
    },
    footerFeatured: {
        paddingBottom: 20,
        ...Centering,
        '& a': {
            width: '100%',
        },
        [theme.breakpoints.down('sm')]: {
            '& a': {
                width: '75%',
            },
        },
    },
    imgFeaturedContainer: {
        padding: 0,
    },
    imgFeatured: {
        width: '100%',
        height: '100%',
    },
    imgFeaturedItem: {
        width: '100%',
        ...Centering,
        backgroundColor: WHITE,
        height: '100%',
        [theme.breakpoints.down('sm')]: {
            marginBottom: '20px',
        },
    },
    skeletonDesktop: {
        display: 'flex',
        justifyContent: 'center',
    },
    buttonViewAllHome: {
        width: '100%',
        margin: 'auto',
        [theme.breakpoints.up('sm')]: {
            maxWidth: 375,
        },
    },
    hamburgerList: {
        width: 250,
    },
}));

export default useStyles;
